<template>
  <v-container class="my-4 py-6">
    <v-row>
      <v-col>
        <h2>Inventarios</h2>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="text-center text-md-right">
        <v-btn 
          text
          color="primary"
          @click="fileImportToggle"
        >
          {{ textImport }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
      <v-col class="text-center text-md-right py-0">
        <v-btn 
          text
          color="primary"
          small
          :disabled="inventoryDocuments.length === 0"
          @click="downloadTemplate"
        >
          Descargar template
        </v-btn>
      </v-col>
    </v-row>
    
    <v-row
      v-if="editing"
      justify="center"
      class="px-2 mx-4 my-6"
    >
      <v-card
        class="px-4 py-4"
        max-width="800"
      >
        <p class="text-primary">
          {{ textAccion }} inventario
        </p>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="onSubmit"
        >
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="inventory.nombre_producto"
                label="Producto"
                :rules="[rules.required]"
                disabled
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="inventory.id_opcion_producto"
                label="Opciones de producto"
                :items="productOptions"
                :rules="[rules.required]"
                :disabled="toUpdate"
                item-text="descripcion"
                item-value="id"
                dense
                solo
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="inventory.id_almacen"
                label="Almacen"
                :items="warehouse"
                :rules="[rules.required]"
                :disabled="toUpdate"
                item-text="nombre_almacen"
                item-value="id"
                dense
                solo
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.number="inventory.cantidad_existente"
                :label="textQuantity"
                type="number"
                :rules="[rules.required]"
                :disabled="inventory.backorder"
              />
            </v-col>
            <v-col
              class="pt-md-6"
              cols="12"
              md="3"
            >
              <v-autocomplete
                class="my-auto"
                v-model="inventory.id_unidad_medida"
                label="Unidad de medida"
                :items="measurementUnits"
                :rules="[rules.required]"
                :disabled="toUpdate"
                item-text="dato"
                item-value="id"
                dense
                solo
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-checkbox
                v-model="inventory.backorder"
                label="Backorder"
                :disabled="disableBackorder"
                @change="showBackordersFields"
              />
            </v-col>
            <v-col
              v-if="inventory.backorder"
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="inventory.nombre_backorder"
                label="Nombre backorder"
              />
            </v-col>
            <v-col
              v-if="inventory.backorder"
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.number="inventory.tiempo_entrega_dias"
                label="Días de entrega"
                type="number"
              />
            </v-col>
            <v-col
              cols="12"
              md="12"
              class="mb-0 pb-0"
            >
              <v-textarea
                v-model="inventory.comentarios"
                label="Comentarios"
                :rules="[rules.required]"
                :disabled="toUpdate"
                rows="3"
                solo
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="updateInventory"
          >
            {{ textAccion }}
          </v-btn>
          <v-btn
            color="primary"
            @click="cancel"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <v-row
      v-if="fileImport"
      justify="center"
      class="px-2 mx-4 my-6 mx-sm-0"
    >
      <v-col
        cols="12"
        md="10"
        sm="12"
      >
        <v-file-input
          v-model="inventoryFile"
          accept=".xlsx"
          solo
          label="Subir archivo"
          @click:clear="clearInventoryVariables"
        >
          <template v-slot:append-outer>
            <v-btn
              style="margin-top: -6px"
              color="primary"
              :disabled="inventoryFile === null"
              @click="uploadFile"
            >
              Enviar
            </v-btn>
          </template>
        </v-file-input>
      </v-col>
    </v-row>
    <v-row
      v-if="fileImport"
      justify="center"
      class="px-2 mx-4 my-6 mx-sm-0"
    >
      <component-loader v-if="importing" />
      <v-col
        v-else
        cols="12"
        md="10"
        sm="12"
      >
        <v-alert
          v-if="importResultsSize"
          border="right"
          colored-border
          type="success"
          elevation="2"
        >
          <p><strong>Inventarios actualizados exitosamente</strong></p>
          Total actualizados: {{ importsSuccess.length }}<br>
          Total en archivo: {{ importResultsSize }}
        </v-alert>
        <v-alert
          v-else-if="emptyFile"
          border="right"
          colored-border
          type="warning"
          elevation="2"
        >
          <p><strong>Archivo sin cantidades para actualizar</strong></p>
        </v-alert>
        <v-alert
          v-if="crashImport.length"
          border="right"
          colored-border
          type="error"
          elevation="2"
        >
          <p><strong>{{ crashImport.join('; ') }}</strong></p>
        </v-alert>
        <v-alert
          v-else-if="importsErrors.length"
          border="right"
          colored-border
          type="error"
          elevation="2"
        >
          <p><strong>Los siguientes productos no pudieron ser actualizados</strong></p>
          <v-list>
            <v-list-item 
              v-for="product in importsErrors"
              :key="product.producto"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ product.producto.replaceAll('|', ', ') }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ product.respuesta_producto[0].mensaje }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-alert>
      </v-col>
    </v-row>
    <v-row
      v-if="!editing && !fileImport"
      justify="center"
      class="px-2 mx-4 my-6 mx-sm-0"
    >
      <v-col
        cols="12"
        md="10"
        sm="12"
      >
        <v-text-field
          solo
          label="Buscar producto"
          append-icon="mdi-magnify"
          clearable
          v-model="search"
          @input="searchText"
          @click:clear="clearText"
          :loading="waitingSearch"
        />
        <p v-if="search && search.length && search.length < 3">
          Escribe al menos tres caracteres
        </p>
      </v-col>
    </v-row>
    <v-row
      v-if="!editing && !fileImport"
      class="my-4"
      justify="center"
    >
      <v-col>
        <v-row
          v-if="!distributorBasicComplete"
          justify="center"
        >
          <h2>Es necesario dar de alta por lo menos una sucursal y un almacén</h2>
        </v-row>
        <div v-else-if="waitingSearch">
          <component-loader />
        </div>
        <div v-else-if="filteredProducts.length">
          <paginate
            name="products"
            :list="filteredProducts"
            :per="perPage"
            class="pl-0"
          >
            <v-row justify="center">
              <v-col
                v-for="product in paginated('products')" 
                :key="product.id"
                md="4"
              >
                <v-card
                  max-width="300"
                  class="mx-4 my-4"
                >
                  <v-card-title class="primary--text">
                    SKU {{ product.sku }}
                  </v-card-title>
                  <v-card-text>
                    <p class="display-1 text--primary">
                      {{ product.nombre_producto }}
                    </p>
                    <div v-if="product.tiene_inventario">
                      Cantidad disponible: {{ product.cantidad_disponible }}
                      <span v-if="product.backorder && product.backorder.backorder">
                        <br>Tiene backorder
                      </span>
                    </div>
                    <div v-else>
                      No tiene inventario
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      color="primary"
                      @click="edit(product)" 
                    >
                      {{ product.tiene_inventario ? "Actualizar" : "Crear"}}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                    >
                      Eliminar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </paginate>
          <paginate-links
            for="products"
            :async="true"
            :limit="5"
            :show-step-links="true"
            :classes="{
              'ul': 'pagination',
              'li': 'page-item',
              'li > a': 'page-link'
            }"
            @change="onPageChange"
          >
          </paginate-links>
        </div>
        <v-row 
          v-else
          justify="center"
        >
          <h2>No hay productos que mostrar</h2>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ApiAuth } from '@/api_utils/axios-base'
import axios from "axios"
import { validationMixin } from '@/mixins/formsValidations.js'
import { inventorySearchMixin } from '@/mixins/inventorySearch.js'
import { mapActions, mapGetters, mapState } from 'vuex'
import componentLoader from '@/components/ComponentLoader'

export default {
  metaInfo: {
    title: 'Actualizar inventario'
  },
  components: {
    componentLoader,
  },
  mixins: [ validationMixin, inventorySearchMixin ],
  data() {
    return {
      editing: false,
      toUpdate: false,
      importing: false,
      fileImport: false,
      inventoryDocuments: [],
      paginate: ['products'],
      perPage: 6,
      responsePage: 1,
      inventoryPage: 1,
      paginateLimit: 1,
      totalItemsProducts: 0,
      totalItemsInventory: 0,
      filteredProducts: [],
      filteredInventory: [],
      lastPage: 1,
      lastPageInventory: 1,
      search: "",
      allProducts: [],
      products: [],
      productOptions: [],
      warehouse: [],
      defaultWarehouseId: null,
      sucursals: [],
      defaultSucursal: {},
      inventory: {
        id_producto: null,
        nombre_producto: null,
        id_almacen: 1,
        cantidad_existente: 0.00,
        id_opcion_producto: null,
        id_unidad_medida: null,
        comentarios: null,
        id_usuario: null,
        hasInventory: false,
        backorder: false,
        id_backorder:  null,
      },
      opcionesProducto: [],
      unidadesMedida: [],
      measurementUnits: [],
      inventoryFile: null,
      importResults: [],
      emptyFile: false,
      crashImport: [],
    }
  },
  computed: {
    ...mapState('usuario', ['userData']),
    ...mapState('products', ['distributorId']),
    ...mapGetters('usuario', ['distributorBasicComplete']),
    importResultsSize() {
      //console.log('size results', this.importResults.length);
      return this.importResults.length
    },
    importsSuccess() {
      //console.log('hubo un cambio en importResults success');
      return this.importResults.length 
        ? this.importResults.filter(result => {
            return result.respuesta_producto.some(responseProduct => responseProduct.mensaje.includes('producto actualizado'))
          })
        : []
    },
    importsErrors() {
      //console.log('hubo un cambio en importResults errors');
      return this.importResults.length 
        ? this.importResults.filter(result => {
            return result.respuesta_producto.some(responseProduct => !responseProduct.mensaje.includes('producto actualizado'))
          })
        : []
    },
    textAccion() {
      return this.toUpdate ? "Actualizar" : "Crear"
    },
    textQuantity() {
      return this.inventory.hasInventory ? 'Cantidad a agregar' : 'Cantidad inicial'
    },
    textImport() {
      return this.fileImport ? 'Actualización manual' : 'Importar'
    },
    disableBackorder() {
      return this.toUpdate
        && this.inventory.hasInventory
        && this.inventory.cantidad_disponible > 0.0
    },
    
  },
  created() {
    this.loadInitialData()
  },
  methods: {
    ...mapActions(['setLoading']),
    async loadInitialData() {
      //await this.getInventoryDistributor()
      this.waitingSearch = true
      await Promise.all([
        this.getProductos(),
        this.getUnits(),
        this.getProductOptions(),
        this.getWarehouses(),
        this.getInventory(),
        this.getDocumentsByDistributor()
      ]).then(response => {
        this.allProducts = response[0].data.results
        this.lastPage = this.calculatePaginateLimit(response[0].data.count, this.perPage)
        this.measurementUnits = response[1].data
        this.productOptions = response[2].data
        this.warehouse = response[3].data
        this.products = response[4].data.results
        this.lastPageInventory = this.calculatePaginateLimit(response[4].data.count, this.perPage)
        const documents = response[5].data
        if (documents.documentos.length > 0) {
          const docs = documents.documentos
          const inventoryDocuments = docs.filter(d => d.nombre_documento.toLowerCase().includes('inventario'))
          this.inventoryDocuments = inventoryDocuments ? inventoryDocuments : []
        }
        // console.log('products initial data: ', this.products);
        // console.log('count product:', response[0].data.count);
        // console.log('count inventory:', response[4].data.count);
        // console.log('lastPage:', this.lastPage);
        // console.log('lastPageInventory:', this.lastPageInventory);

        this.defaultWarehouseId = this.userData.almacenId
        this.defaultSucursal = this.userData.sucursalId
        // console.log('sucursalId: ', this.defaultSucursal);
        // console.log('defaultWarehouseId: ', this.defaultWarehouseId);
        this.allProducts.map(product => {
          this.addIfHasInventory(product, this.defaultWarehouseId)
        })
        //console.log('all products initia data: ', this.allProducts)
        this.filterByName()
        this.waitingSearch = false
      })
    },
    getProductos() {
      return ApiAuth.get('/api/products/')
    },
    getUnits() {
      return ApiAuth.get('/api/unidades-medida/')
    },
    getProductOptions() {
      return ApiAuth.get('/api/opciones-producto/')
    },
    getWarehouses() {
      return ApiAuth.get(`/inventarios/api/almacenes/?distribuidor_id=${this.userData.distribuidorId}`)
    },
    getInventory() {
      return ApiAuth.get(`/inventarios/api/inventarios-sucursal/?id_sucursal=${this.userData.sucursalId}`)
    },
    getDocumentsByDistributor() {
      return ApiAuth.post('/personas/documento-distribuidor', {
          id_distribuidor: this.distributorId
        })
    },
    async getInventoryDistributor() {
      const inventory = await ApiAuth.get(`/inventarios/api/inventarios-sucursal/?id_sucursal=${this.userData.sucursalId}`) 
      const inventoryData = await inventory.data
      this.products = inventoryData.results
    },
    async getPaginatedInventoryDistributor(page) {
      const inventory = await ApiAuth.get(`/inventarios/api/inventarios-sucursal/?id_sucursal=${this.userData.sucursalId}&page=${page}`) 
      const inventoryData = await inventory.data
      return inventoryData.results
    },
    filterByName() {
      const searchLower = this.search.toLowerCase()
      const filter = this.allProducts.filter(product => {
        const productNameLower = product.nombre_producto.toLowerCase()
        if (productNameLower.includes(searchLower)) {
          return product
        }
      })
      this.filteredProducts = filter
    },
    edit(product) {
      this.editing = true
      this.inventory.id_producto = product.id
      this.inventory.nombre_producto = product.nombre_producto
      this.inventory.id_usuario = this.userData.id
      this.inventory.cantidad_existente = 0.00
      if (product.tiene_inventario) {
        this.inventory.id_almacen = this.defaultWarehouseId
        this.inventory.hasInventory = true
        this.toUpdate = true
        // console.log("inventario: ", this.products);
        // console.log("defaultWarehouseId: ", this.defaultWarehouseId)
        // console.log("productId: ", product.id);
        const currentInventory = this.isSearch 
                                  ? this.findSearchInventory(product.id, this.defaultWarehouseId) 
                                  : this.findInventory(product.id, this.defaultWarehouseId);
        // console.log('currentInventory edit', currentInventory);
        if (currentInventory) {
          this.inventory.id_opcion_producto = currentInventory.producto.opcion_producto[0].id
          //console.log('id opcion producto: ', this.inventory.id_opcion_producto);
          this.inventory.id_unidad_medida = currentInventory.producto.unidad_medida.id
          this.inventory.comentarios = currentInventory.comentarios.length 
            ? currentInventory.comentarios 
            : "Sin comentarios"
          this.inventory.cantidad_disponible = currentInventory.cantidad_disponible 
        }
        if (product.backorder !== null && product.backorder.backorder) {
          this.inventory.id_backorder = product.backorder.id
          this.inventory.nombre_backorder = product.backorder.nombre
          this.inventory.backorder = product.backorder.backorder
          this.inventory.tiempo_entrega_dias = product.backorder.tiempo_entrega_dias
        }
      } else {
        this.inventory.id_almacen = null
        this.inventory.hasInventory = false
        this.toUpdate = false
      }
    },
    async updateInventory() {
      if(!this.$refs.form.validate()) {
        return 
      }
      try {
        //this.$store.commit('setLoading', true)
        //console.log("Datos a mandar: ", this.inventory);
        const responseUpdate = 
          await ApiAuth.post('/inventarios/actualizar-inventario', this.inventory)
        const responseUpdateData = await responseUpdate.data
        //console.log("response update: ", responseUpdate);
        //console.log("response update update: ", responseUpdateData);
        if (this.inventory.backorder && !this.inventory.id_backorder) {
          const backorder = {
            nombre: this.inventory.nombre_backorder,
            backorder: this.inventory.backorder,
            tiempo_entrega_dias: this.inventory.tiempo_entrega_dias,
            id_producto: this.inventory.id_producto,
            id_autor: this.userData.id
          }
          const responseBackorder = await ApiAuth.post('/crear-backorder', backorder)
          const responseBackorderData = await responseBackorder.data

          //console.log('respuesta crear backorder: ', responseBackorderData)
        }
        if (responseUpdateData.error) {
          this.$store.commit('setAlert', {
            show: true,
            msg: `No se pudo ${this.textAccion} el inventario: ${responseUpdateData.e.join(', ')}`,
            type: "error"
            })
        } else {
          if (this.isSearch) {
            await this.updateInventoryInSearch()
          } else {
            if (!this.toUpdate) {
              const inventoryResult = await this.searchInventoryByName(this.inventory.nombre_producto, this.inventory.id_almacen)
              const inventoryToAdd = inventoryResult.results[0]
              this.products.push(inventoryToAdd)
            }
            const indexProduct = this.allProducts.findIndex(product => product.id === this.inventory.id_producto)
            // console.log('indexProduct: ', indexProduct);
            const pageToUpdate = this.calculatePaginateLimit(indexProduct, 20)
            const indexInventory = this.products.findIndex(product => product.producto.id === this.inventory.id_producto)
            // console.log('indexInventory:', indexInventory);
            const pageToUpdateInventory = this.calculatePaginateLimit(indexInventory, 20)
            // console.log('Page to update: ', pageToUpdate);
            // console.log('Page to update inventory: ', pageToUpdateInventory);
            const results = await this.getPaginatedInventoryDistributor(pageToUpdateInventory)
            // console.log('results paginatedInventoryDistributor:', results);
            /*results.forEach(result => {
              this.products = this.products
                .map(inventory => inventory.id === result.id 
                  ? result 
                  : inventory)
            })*/
            this.products = results
            // console.log('Si se agregaron los inventarios actualizado? ', this.products);
            this.updateProductInAllProducts(this.inventory)
            this.editing = false
            this.clear()
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        //this.$store.commit('setLoading', false)
        //this.fetchProducts()
      }
    },
    async updateInventoryInSearch() {
      if (!this.toUpdate) {
        const inventoryResult = await this.searchInventoryByName(this.inventory.nombre_producto, this.inventory.id_almacen)
        const inventoryToAdd = inventoryResult.results[0]
        this.filteredInventory.push(inventoryToAdd)
      }
      this.updateProductInSearch(this.inventory)
      this.editing = false
      this.clear()
    },
    cancel() {
      this.editing = false
      this.clear()
    },
    clear() {
      this.inventory = {
        id_producto: null,
        nombre_producto: null,
        id_almacen: null,
        cantidad_existente: 0.00,
        id_opcion_producto: null,
        id_unidad_medida: null,
        comentarios: null,
        id_usuario: null
      }
    },
    clearText() {
      this.search = ""
      this.isSearch = false
      this.waitingSearch = false
      this.filteredProducts = this.allProducts
      //this.filterByName()
    },
    async onPageChange(toPage, fromPage) {
      if (!this.isSearch) {
        const limitProducts = this.calculatePaginateLimit(this.allProducts.length, this.perPage)
        const limitInventory = this.calculatePaginateLimit(this.products.length, this.perPage)
        if (toPage === limitProducts && limitProducts < this.lastPage) {
          this.waitingSearch = true
          this.responsePage++
          if (toPage === limitInventory && limitInventory < this.lastPageInventory ) {
            this.inventoryPage++
            const results = await this.getPaginatedInventoryDistributor(this.inventoryPage)
            this.products.push(...results)
          }
          //console.log('inventarios: ', this.products);
          await this.getPaginatedProducts(this.responsePage)
          this.waitingSearch = false 
        }
      }
    },
    async searchText() {
      try {
        this.isSearch = true
        const cleanSearch = this.search.trim()
        if (cleanSearch.length === 0) {
          this.clearText()
        } else {
          this.waitingSearch = true
          if (cleanSearch.length > 2) {
            const filteredInventoryResult = []
            const searchResult = await this.searchProductByName(this.search, this.defaultSucursal)
            // console.log('searchResult: ', searchResult);
            let filtered = searchResult
            // this.filteredProducts = searchResult
            // console.log('this.defaultWarehouseId: ', this.defaultWarehouseId);
            for (const product of filtered) {
              const inventoryResult = await this.searchInventoryByName(product.nombre_producto, this.defaultWarehouseId)
              // console.log('inventoryResult search: ', inventoryResult);
              if (inventoryResult.count > 0) {
                // console.log('dentro currentInventory');
                product.tiene_inventario = true
                product.cantidad_disponible = inventoryResult.results[0].cantidad_disponible
                filteredInventoryResult.push(inventoryResult.results[0])
              } else {
                // console.log('else dento currentInventory');
                product.tiene_inventario = false
                product.cantidad_disponible = "0.00"
              }
              // console.log('product Despues:', product);
            }
            this.filteredProducts = filtered
            this.filteredInventory = filteredInventoryResult
            //console.log('this.filteredProducts busqueda: ', this.filteredProducts);

            this.waitingSearch = false
          }
        }
      } catch (error) {
        console.error('Error al buscar producto por nombre');
      }
    },
    async getPaginatedProducts(page) {
      const productResponse = await ApiAuth.get(`/api/products/?page=${page}`)
      const productResponseData = await productResponse.data

      if (productResponseData.detail) {
        //console.log("No se pudieron obtener los producto. ");
      } else {
        const tempProducts = productResponseData.results
        tempProducts.forEach(tempProd => {
          //console.log('Estoy entrando aqui?', tempProd);
          this.addIfHasInventory(tempProd, this.defaultWarehouseId)
        })
        this.allProducts.push(...tempProducts)
        this.filteredProducts = this.allProducts
        //this.filterByName()
      }
    },
    calculatePaginateLimit(arrayProductsLength, perPage) {
      return arrayProductsLength === 0 
        ? 1 
        : (arrayProductsLength % perPage) > 0 
          ? parseInt(arrayProductsLength / perPage) + 1
          : parseInt(arrayProductsLength / perPage)
    },
    updateProductInAllProducts(inventory) {
      const productToUpdate = this.allProducts.find(p => p.id === inventory.id_producto)
      // console.log('productToUpdate - updateProductInAllProducts: ', productToUpdate);
      if (productToUpdate) {
        // console.log('productToUpdate - SI TIENE');
        this.addIfHasInventory(productToUpdate, inventory.id_almacen)
      }
      // console.log('productoActualizado? ', productToUpdate);
    },
    updateProductInSearch(inventory) {
      const productToUpdate = this.filteredProducts.find(p => p.id === inventory.id_producto)
      // console.log('productToUpdate - updateProductInSearch: ', productToUpdate);
      if (productToUpdate) {
        // console.log('productToUpdateSearch - SI TIENE');
        this.addIfHasSearchInventory(productToUpdate, inventory.id_almacen)
      }
      // console.log('productoActualizado inSearch? ', productToUpdate);
    },
    findInventory(productId, warehouseId) {
      return this.products.find(p => {
        return p.producto.id === productId
          && p.almacen.id === warehouseId
          })
    },
    findSearchInventory(productId, warehouseId) {
      return this.filteredInventory.find(p => {
        return p.producto.id === productId
          && p.almacen.id === warehouseId
          })
    },
    addIfHasInventory(product, warehouseId) {
      const currentInventory = this.findInventory(product.id, warehouseId)
      //console.log('currentInventory - addIfHasInventory: ', currentInventory);
      if (currentInventory) {
        //console.log('dentro currentInventory');
        product.tiene_inventario = true
        product.cantidad_disponible = currentInventory.cantidad_disponible 
      } else {
        //console.log('else dento currentInventory');
        product.tiene_inventario = false
        product.cantidad_disponible = "0.00"
      }
    },
    async addIfHasSearchInventory(product, warehouseId) {
      const inventoryResult = await this.searchInventoryByName(product.nombre_producto, warehouseId)
      //console.log('inventorySearchResult search: ', inventoryResult);
      if (inventoryResult.count > 0) {
        //console.log('dentro currentSearchInventory');
        product.tiene_inventario = true
        product.cantidad_disponible = inventoryResult.results[0].cantidad_disponible
        //filteredInventoryResult.push(inventoryResult.results[0])
      } else {
        //console.log('else dento currentSearchInventory');
        product.tiene_inventario = false
        product.cantidad_disponible = "0.00"
      }
    },
    async uploadFile() {
      try {
        this.importing = true
        // console.log('inventoryFile', this.inventoryFile.name);
        const fileName = this.inventoryFile.name
        const formData = new FormData()
        formData.append('File_1', this.inventoryFile)
        const responseUpload = await ApiAuth.post('/inventarios/subir-archivo-inventario', 
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          const responseUploadData = await responseUpload.data
          if (responseUploadData.mensaje.includes('Archivo guardado')) {
            const fileToImport = responseUploadData.archivos[0][`${fileName}`]
            const responseImport = await ApiAuth.post('/inventarios/importar-archivo-inventario',{
              url_archivo: fileToImport,
              id_distribuidor: this.userData.distribuidorId,
              id_usuario: this.userData.id
            })
            const responseImportData = await responseImport.data
            // console.log('responseImportData:', responseImportData);
            if (responseImportData.error) {
              this.crashImport.push('No se pudo procesar el archivo: ' + responseImportData.e.join(', '))
              // console.log('crashImport:', this.crashImport);
            } else if (responseImportData.respuestas.length > 0) {
              this.importResults = responseImportData.respuestas
            } else {
              this.emptyFile = true
            }
            //console.log('respuesta importResults: ', this.importResults)
          } else {
            //console.log('no entro al includes');
          }
        setTimeout(() => {
          this.importing = false
        }, 500)
        
      } catch (error) {
        
      }
    },
    clearInventoryVariables() {
      this.inventoryFile = null
      this.importResults = []
      this.emptyFile = false
      this.crashImport = []
    },
    fileImportToggle() {
      if(this.importResultsSize) {
        //console.log('importResults tiene datos, se debe recargar todo');
        this.clearInventoryVariables()
        this.loadInitialData()
      }
      this.fileImport = !this.fileImport
    },
    showBackordersFields() {
      if (this.inventory.backorder) {
        this.inventory.cantidad_existente = 0.0
      }
    },
    downloadTemplate() {
      const documents = this.inventoryDocuments
      for(const doc of documents) {
        this.downloadDocument(doc)
      }
    },
    async downloadDocument(doc) {
      try {
        // console.log('Descargar documento', doc);
        const documentUrl = doc.documento;
        const fileName = doc.nombre_documento;

        const response = await axios({
          url: documentUrl,
          method: "GET",
          responseType: "blob",
        });
        const responseData = await response.data;
        const fileblob = new Blob([responseData]);
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(fileblob)
        link.download = `${fileName}`
        link.click()
      } catch (error) {
        console.error('Error al intentar descargar un documento', error);
      }
    },
  },
}
</script>

<style>
.pagination {
  display: flex;
  margin-top: 16px;
  text-align: center;
  justify-content: center;
  list-style-type: none;
}
.page-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}
.page-link {
  font-weight: bolder;
}
.active {
  background: #2D4761;
}
.align-right {
  text-align: right;
}
</style>